// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
// import i18n from './i18n'
import VueColumnsResizableVuetify from 'vue-columns-resizable-vuetify'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import VueMask from 'v-mask'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

Vue.component('phone-input', VuePhoneNumberInput)
Vue.use(VueMask)

Vue.use(VueColumnsResizableVuetify)
Vue.config.productionTip = false

Vue.filter('dateFormat', (val) => {
  if (!val) return
  val = val.toString()
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const dateSplit = val.split('T')
  const date = dateSplit[0].split('-')
  date[1] = months[parseInt(date[1]) - 1]
  const time = dateSplit[1].split('.')[0].split(':')
  return `${date.join(' ')}, ${time[0]}:${time[1]}`
})
Vue.filter('dateFormatOnly', (val) => {
  if (!val) return
  val = val.toString()
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const dateSplit = val.split('T')
  const date = dateSplit[0].split('-')
  date[1] = months[parseInt(date[1]) - 1]
  return date.join(' ')
})
Vue.filter('roleFormat', (val) => {
  if (!val) return
  return val.toLowerCase().split('role_')[1]
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !store.state.auth.isAuthenticated) {
    next({ name: 'Login', params: { redirect: to.path } })
  } else if (to.path === '/login' && store.state.auth.isAuthenticated) {
    next('/')
  } else next()
})

new Vue({
  router,
  store,
  vuetify,
  // i18n,
  render: (h) => h(App),
}).$mount('#app')

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi',
})
